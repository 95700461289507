import React, { Fragment, useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "react-moment";
import { setAlert } from "../../../../action/alert";
import Alert from "../../../layout/Alert";
import './DeviceDACLogs.css'
import { format } from "date-fns";
import { dacStatsPageSize } from "../DACStatisticsConstants";


const DeviceDACLogs = ({
    deviceDetails,
    dacData = [],
    getDeviceDACLogsData,
    showSearch = false,
    showCount = false
}) => {

    const [deviceDACLogsData, setDeviceDACLogsData] = useState([]);
    const [updateFlag, setUpdateFlag] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [macAddress, setMacAddress] = useState("");

    const headers = {
        serialNumber: "S.No",
        timestamp: 'Created At'
    };

    // useEffect(() => {
    //     filter()
    // }, [])

    useEffect(() => {
        if (deviceDetails && deviceDetails instanceof Object && deviceDetails.hasOwnProperty('macAddress')) {
            getDeviceDACLogs()
        }
    }, [deviceDetails])

    useEffect(() => {
        if (updateFlag) {
            getDeviceDACLogs()
        }
    }, [updateFlag])

    useEffect(() => {
        setDeviceDACLogsData(dacData)
    }, [dacData])

    const filter = () => {
        setUpdateFlag(Math.random());
    }

    const getNextPage = () => {
        const newPg = pageIndex + 1
        setPageIndex(newPg);
        setIsLoading(true)
        getDeviceDACLogsData({
            macAddress,
            //pagination not yet implemented in API, check if param names are correct after implementation
            pgIndex: newPg,
            pgSize: dacStatsPageSize
        }, () => {
            setIsLoading(false)
        })
    }

    const getPrevPage = () => {
        const newPg = pageIndex - 1;
        setPageIndex(newPg);
        setIsLoading(true)
        getDeviceDACLogsData({
            macAddress,
            //pagination not yet implemented in API, check if param names are correct after implementation
            pgIndex: newPg,
            pgSize: dacStatsPageSize
        }, () => {
            setIsLoading(false)
        })
    }
    const getDeviceDACLogs = () => {
        setIsLoading(true)
        getDeviceDACLogsData({ macAddress: deviceDetails?.macAddress ?? "" }, () => {
            setIsLoading(false);
        })
    }

    const searchDACLogs = () => {
        setIsLoading(true)
        setPageIndex(0);
        getDeviceDACLogsData({
            macAddress,
            //pagination not yet implemented in API, check if param names are correct after implementation
            pgIndex: 0,
            pgSize: dacStatsPageSize
        }, () => {
            setIsLoading(false)
        })
    }

    return (
        <Fragment>
            {showSearch &&
                <div className="content-block bottom-shadow">
                    <div className="py-15 ">
                        <div className="row mb-0"
                            style={{ display: 'flex', alignItems: "center" }}
                        >
                            <div
                                style={{ maxWidth: "250px" }}
                                className={"search-device not-found"}
                            >
                                <i className="material-icons search-icon">
                                    {" "}
                                    search
                                </i>
                                < input
                                    style={{ borderRight: "1px solid #ccc", maxWidth: "200px" }}
                                    className="add-input notransform border-0"
                                    required
                                    placeholder={"Enter MAC address"}
                                    type={"text"}
                                    value={macAddress}
                                    onChange={(e) => {
                                        setMacAddress(e?.target?.value)
                                    }}
                                />
                            </div>
                            <div className="col" style={{ padding: "0px !important" }}>
                                <button
                                    style={{ margin: "16px 0px", height: "40px" }}
                                    className="btn btn-style-3  sm mr-10 font-400"
                                    onClick={e => searchDACLogs()}
                                    disabled={macAddress === ""}
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Alert />

            {isLoading ?
                <div
                    style={{
                        display: "grid",
                        placeItems: "center",
                        height: "80vh",
                    }}
                >
                    <CircularProgress size="5em" style={{ color: "#AFB42B" }} />
                </div>
                :
                <div>
                    {showCount && <div className="content-block bottom-shadow">
                        <div style={{ margin: "8px", marginLeft: "16px", display: "flex" }} className="page-title">
                            <h3>{`DAC Count : ${dacData?.length || "-"}`}</h3>
                        </div>
                    </div>}
                    <div className="content-block bottom-shadow py-15 mb-30">
                        <div className="row mb-0">
                            <div className="col s12">
                                <div
                                    className="table-wrapper mb-20"
                                    style={{
                                        scrollBehavior: "auto",
                                        maxHeight: "58vh",
                                    }}
                                >
                                    <table
                                        width="100%"
                                        align="center"
                                        className="table style-4 mb-20 deviceDACLogsTable"
                                    >
                                        <thead
                                            style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                                        >
                                            <tr>
                                                {Object.keys(headers).map((key, i) => (
                                                    <th key={i}>{headers[key]}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {deviceDACLogsData && deviceDACLogsData.length === 0 ? (
                                                <tr>
                                                    <td colSpan={Object.keys(headers).length}>
                                                        No Data found
                                                    </td>
                                                </tr>
                                            ) : (
                                                deviceDACLogsData && deviceDACLogsData.length > 0 && deviceDACLogsData.map((element, index) => {
                                                    return (
                                                        <tr key={index} id={index}>
                                                            {Object.keys(headers).map((key, i) => {
                                                                return (
                                                                    key == "serialNumber" ?
                                                                        <td key={i} className={String(element[index]).length > 60 ? "breakWord" : ""}>
                                                                            {index + 1}
                                                                        </td>
                                                                        :
                                                                        <td key={i} className={String(element[index]).length > 200 ? "breakWord" : ""}>
                                                                            {
                                                                                format(new Date(Number(element)), "dd MMM yyyy, hh:mm a")
                                                                            }
                                                                        </td>
                                                                );
                                                            })}
                                                        </tr>
                                                    );
                                                })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {/* uncomment for pagination */}
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <button
                                        className="btn btn-style-3  sm  mr-10 font-400"
                                        onClick={() => getPrevPage()}
                                        disabled={pageIndex === 0}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className="btn btn-style-3  sm  mr-10 font-400"
                                        onClick={() => getNextPage()}
                                        disabled={dacData?.length < dacStatsPageSize}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    setCustomAlert: (message, alertType) => dispatch(setAlert(message, alertType))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDACLogs);
