import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../../components/dashboard/DashBoard";
import Report from "../report/Report";
import NotFound from "../../components/layout/NotFound";
import GetdModel from "../../components/device/GetdModel";
import { connect } from "react-redux";
import SearchModel from "../../components/device/SearchModel";
import SearchDevice from "../../components/device/SearchDevice";
import Statistics from "../../components/dashboard/Statistics";
import ProductMetrics from "../../components/dashboard/ProductMetrics";
import FotaJobs from "../../components/dashboard/FotaJobs";
import FirmwareInfo from "../report/FirmwareInfo";
// import Alert from "../layout/Alert";
import PrivateRoute from "../../components/routing/PrivateRoute";
import UserInfoPage from "./pages/UserInfoPage";
import NavBar from "./components/NavBar";
import FeedbackPage from "./pages/FeedbackPage";
import MetricsCharts from "./pages/MetricsChart";
import ManufacturedDevicesPage from "./pages/ManufacturedDevciesPage"
import DeviceFirmware from "../device/DeviceFirmware/Firmware";

import AddNewDeviceModel from "../../components/device/AddNewDeviceModel"

import IDUEEPROMConfig from "../../components/device/IDUEEPROMConfig/IDUConfig"
import customConfig from "../../config";

import QRCode from "./../qrcode/qrcode"
import DeviceDealerMgmt from "../../components/deviceDealerMgmt/deviceDealerMgmt"

import LineTestUserSearchDevice from './../LineTestUser/SearchDevice'
import NotificationManagement from "../notificationManagement/index";
import DACStatistics from "../device/DACStatistics/DACStatistics";

const Routes = ({ isAuthenticated, userLevel, userRoles, tenantInfo }) => {
  return (
    <section
      //className="container"
      className={` ${tenantInfo.primaryColor
        ? tenantInfo.primaryColor +
        "-theme " +
        tenantInfo.secondaryColor +
        "-theme-secondary"
        : "dark-blue-theme lime-theme-secondary"
        }`}
    >
      {isAuthenticated ? (
        <div>
          <NavBar />
        </div>
      ) : null}

      <Switch>
        <PrivateRoute exact path="/feedback" component={FeedbackPage} />
        <PrivateRoute exact path="/metricsChart" component={MetricsCharts} />
        {
          (userRoles && userRoles.includes(customConfig.roleLineTestUser)) ?
            <PrivateRoute path="/dashboard" component={LineTestUserSearchDevice} />
            :
            (userLevel == 0 && (userRoles && userRoles.includes(customConfig.roleFactoryAdmin))) ||
              userRoles && userRoles.includes(customConfig.roleFieldOperator) ?
              <PrivateRoute
                exact
                path="/dashboard"
                component={SearchDevice}
              />
              :
              <PrivateRoute path="/dashboard" component={UserInfoPage} />
        }
        <PrivateRoute
          exact
          path="/dashboardBack"
          component={() => <Dashboard showDetail={true} />}
        />
        {
          userLevel > 0 && <PrivateRoute exact path="/searchDevice" component={SearchDevice} />
        }
        <PrivateRoute exact path="/device" component={GetdModel} />
        <PrivateRoute exact path="/deviceDetail" component={SearchModel} />
        <PrivateRoute
          exact
          path="/deviceDetail/:modelNum"
          component={SearchModel}
        />
        <PrivateRoute exact path="/report/:homeId" component={Report} />
        <PrivateRoute exact path="/statistics" component={Statistics} />
        <PrivateRoute exact path="/productMetrics" component={ProductMetrics} />
        <PrivateRoute exact path="/fotaJobs" component={FotaJobs} />
        <PrivateRoute exact path="/firmwareInfo" component={FirmwareInfo} />

        <PrivateRoute exact path="/manufacturedDevices" component={ManufacturedDevicesPage} />
        <PrivateRoute exact path="/deviceFirmware" component={DeviceFirmware} />

        <PrivateRoute exact path="/addNewDeviceModel" component={AddNewDeviceModel} />

        <PrivateRoute exact path="/idueepromConfig" component={IDUEEPROMConfig} />


        <PrivateRoute exact path="/qrcode" component={QRCode} />

        <PrivateRoute exact path="/deviceDealer" component={DeviceDealerMgmt} />

        <PrivateRoute exact path="/notification" component={NotificationManagement} />
        <PrivateRoute exact path="/DACStatistics" component={DACStatistics} />


        <Route path="/pageNotFound" component={NotFound} />
        <Route component={NotFound} />
        <Redirect from="*" to="/pageNotFound" />
      </Switch>
    </section>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  userRoles: state.auth.userRoles,
  userLevel: state.auth.userLevel,
  tenantInfo: state.tenant.tenantInfo,
});
export default connect(mapStateToProps, {})(Routes);
